import { Campaign } from './types/campaign';

export default class PageConfiguration {
  /**
   * The URL to the published CSV export of the data (e.g. a Google Spreadsheet)
   */
  static DataSource: string = 'https://helpukraine.ingelheim.mobi/api/';
  /**
   * The maximum age of the locally cached data before it gets refreshed
   */
  static MaxDataAgeInMinutes: number = 5;
  /**
   * Option to turn logging to the browser console on or off
   */
  static LogToConsole: boolean = true;
  /**
   * Decide if the data should be refreshed automatically or only on explicit user interaction (keep on true for now, manual refresh needs to be implemented)
   */
  static AutoRefresh: boolean = true;
  /**
   * The URL for the page - NO trailing / allowed
   */
  static PageUrl: string = 'https://helpukraine.ingelheim.mobi';
  /**
   * Image used as default one for social cards when sharing.
   * Use path from server root (e.g. '/yourFileInTheStaticFolder.jpg') - place it in the 'donation-website/static/' folder of this project.
   * Sub-folders are possible, remember to add them to the path in that case
   */
  static defaultPageSocialCardImage: string = '/media/tim-mossholder-BQa--UCtFqg-unsplash_1200.jpg';
  /**
   * The default description used in the page metadata on pages that do not override it (e.g. the Imprint)
   */
  static defaultDescription: string = 'Collecting needed things for the people in Ukraine.';
  /**
   * File to generate the icons for the page from - for best results use a square image and make sure it has 1024 x 1024 pixels or more.
   * Image should be located in 'src/images/' (e.g. 'src/images/yourIconTemplate.jpg'), and that part of the path needs to be part of the configuration
   */
  static pageIconFile: string = '/media/custom-images/9153349_free_icons_design_ukraine_nation_country_love_heart.svg';
  /**
   * The title for a page - default fallback if a page does not provide it's own title
   */
  static pageTitle: string = '#StandWithUkraine';
  /**
   * The template for the page titles in the browser - will change the %s to the title of the currently loaded page
   */
  static titleTemplate: string = '%s · #StandWithUkraine';
  /**
   * The contact details that are used in the imprint
   */
  static ImprintContact = {
    NameOfResponsible: 'Manuel Feller',
    AddressLine1: 'Postfach 1230',
    AddressLine2: '',
    ZipCode: '55205',
    City: ' Ingelheim am Rhein',
    PhoneNumber: '',
    ContactEmail: 'helpukraine@ingelheim.mobi',
    PrivacyEmail: 'privacy@ingelheim.mobi',
  };
  /**
   * Mentions of image contributions
   */
  static ImageContributions = [
    'Ehimetalor Akhere Unuabona, "We Love Ukraine" (www.unsplash.com)',
    'Kokota, "War in Ukraine icon pack" (www.iconfinder.com)',
    'LINECTOR, "Ukraine icon pack" (www.iconfinder.com)',
  ];
  /**
   * The configuration for the campaign(s) that are shown on the generated website
   */
  static CampaignDetails: Campaign[] = [
    {
      Key: 'civilian-2023',
      CampaignType: 'financialSupport',
      TitleImage: '/media/custom-images/2022-05-civ-kharkiv/9d410a843f4867584f16b3364d1f0f3d.jpg',
      DetailsFile: './src/campaigns/cvh1.txt',
      UrlSlug: 'civilian-2023',
      Status: 'collecting',
      Photos: [],
      StartDate: new Date('2023-04-18'),
      CollectionEndDate: new Date('2023-06-30'),
      Languages: {
        de: {
          Title: 'Hilfe für Zivilisten in der Ost-Ukraine',
          ShortCampaignDescription:
            'Viele Menschen sind gezwungen, innerhalb der Ukraine umzusiedeln oder sehen sich der harten Realität gegenüber, in der Nähe oder direkt an der Front zu leben, mit einem Mangel an grundlegenden humanitären Gütern und Medikamenten.',
          ShortDonationDescription:
            'Hier finden Sie eine Liste von Dingen, die von Flüchtlingen innerhalb der Ukraine gerade am dringendsten gebraucht werden.',
        },
        en: {
          Title: 'Humanitarian aid for civilians in Eastern Ukraine ',
          ShortCampaignDescription:
            'Many people are forced to relocate within Ukraine or are facing the harsh reality of living close to or directly on the frontline with a scarcity of basic humanitarian goods and medicaments.',
          ShortDonationDescription: 'Here you can find the list of items that are the most urgent for the refugees within Ukraine.',
        },
      },
    },
    {
      Key: 'soldiers-2023',
      CampaignType: 'financialSupport',
      TitleImage: '/media/custom-images/2022-05-civ-kharkiv/9d410a843f4867584f16b3364d1f0f3d.jpg',
      DetailsFile: './src/campaigns/cvh1.txt',
      UrlSlug: 'soldiers-2023',
      Status: 'collecting',
      Photos: [],
      StartDate: new Date('2023-04-18'),
      CollectionEndDate: new Date('2023-06-30'),
      Languages: {
        de: {
          Title: 'Humanitäre Hilfe für Soldaten in der Ostukraine',
          ShortCampaignDescription:
            'Unsere Freunde aus Kiew haben uns kürzlich kontaktiert, um sie beim Sammeln knapper Güter zu unterstützen, die dann direkt an die Soldaten geliefert werden, die die Freiheit der Ukraine und Europas auf einem Schlachtfeld in der Ostukraine (Gebiet Charkiw & Donezk) verteidigen.',
          ShortDonationDescription:
            'Hier finden Sie eine Liste von Dingen, die von Soldaten innerhalb der Ukraine gerade am dringendsten gebraucht werden.',
        },
        en: {
          Title: 'Humanitarian aid for soldiers in Eastern Ukraine',
          ShortCampaignDescription:
            'Our friends who are based in Kyiv contacted us recently to support them in collecting scarce goods which will be then delivered directly to the soldiers defending the freedom of Ukraine and Europe on a battlefield in Eastern Ukraine (Kharkiv & Donetsk area).',
          ShortDonationDescription: 'Here you can find the list of items that are the most urgent for the soldiers within Ukraine.',
        },
      },
    },
    {
      Key: 'civilian',
      CampaignType: 'civilianSupport',
      TitleImage: '/media/custom-images/2022-05-civ-kharkiv/9d410a843f4867584f16b3364d1f0f3d.jpg',
      DetailsFile: './src/campaigns/cvh1.txt',
      UrlSlug: 'civilian-help-1',
      Status: 'closed',
      Photos: [],
      StartDate: new Date('2022-05-17'),
      CollectionEndDate: new Date('2022-06-17'),
      Languages: {
        de: {
          Title: 'Hilfen für Zivilisten in Kharkiv',
          ShortCampaignDescription:
            'Viele Menschen mussten vor dem Krieg innerhalb der Ukraine flüchten und in eine andere sichere Stadt ziehen. Diese Menschen brauchen unsere Hilfe.',
          ShortDonationDescription:
            'Hier finden Sie eine Liste von Dingen, die von Flüchtlingen innerhalb der Ukraine gerade am dringendsten gebraucht werden.',
        },
        en: {
          Title: 'Support for civilians in Kharkiv',
          ShortCampaignDescription:
            'Many people had to run away from the war in Ukraine and move to another city where is safe, and need help.',
          ShortDonationDescription: 'Here you can find the list of items that are the most urgent for the refugees within Ukraine.',
        },
      },
    },
    {
      Key: 'donation-batteries',
      CampaignType: 'financialSupport',
      UrlSlug: 'donation-batteries',
      Status: 'closed',
      Photos: ['/media/custom-images/2022-12-20-batteries/delivery1.png','/media/custom-images/2022-12-20-batteries/delivery2.png'],
      StartDate: new Date('2022-12-20'),
      Languages: {
        de: {
          Title: 'Donate for batteries for portable radios',
          ShortDonationDescription:
            'A couple of friends defending the freedom of Ukraine and Europe on a battlefield in Eastern Ukraine urgently need 20x batteries for portable digital radios. With your donation we can purchase them in Germany and send them over.',
        },
        en: {
          Title: 'Donate for batteries for portable radios',
          ShortDonationDescription:
            'A couple of friends defending the freedom of Ukraine and Europe on a battlefield in Eastern Ukraine urgently need 20x batteries for portable digital radios. With your donation we can purchase them in Germany and send them over.',
        },
      },
    },
    {
      Key: 'v1',
      CampaignType: 'volunteering',
      Status: 'collecting',
      Photos: [],
      StartDate: new Date('2022-05-02'),
      Languages: {
        de: {
          Title: 'Helfer zum Sortieren von Waren',
          ShortDonationDescription:
            'Wir suchen Freiwillige, die uns in Ingelheim mit dem Sortieren und Verpacken von Waren für weiteren Transport helfen.',
        },
        en: {
          Title: 'Helpers to sort goods',
          ShortDonationDescription: 'We are looking for volunteers to help us with sorting goods in Ingelheim and packing our transports.',
        },
      },
    },
    {
      Key: 'v2',
      CampaignType: 'volunteering',
      Status: 'collecting',
      Photos: [],
      StartDate: new Date('2022-05-01'),
      Languages: {
        de: {
          Title: 'Fahrer: Führerscheinklasse B oder C',
          ShortDonationDescription:
            'Wir suchen Fahrer, die uns mit dem Transport von Waren zur ukrainischen Grenze helfen können. Du besitzt einen Van? Um so besser!',
        },
        en: {
          Title: 'Drivers: category B or C',
          ShortDonationDescription: 'Looking for drivers to help us transport goods to Ukrainian border. If you own a van, great!',
        },
      },
    },
    {
      Key: 'deliverybix',
      CampaignType: 'civilianSupport',
      Status: 'closed',
      UrlSlug: 'basic-needs-1',
      Photos: [
        '/media/custom-images/2022-04-basic-needs/2022-04-basic-needs-1.jpg',
        '/media/custom-images/2022-04-basic-needs/2022-04-basic-needs-2.jpg',
        '/media/custom-images/2022-04-basic-needs/2022-04-basic-needs-3.jpg',
      ],
      ShortDonationDescription: 'Here you can find the list of items that were donated.',
      StartDate: new Date('2022-04-02'),
      Languages: {
        de: {
          Title: 'Grundbedarf – April 2022',
        },
        en: {
          Title: 'Basic needs – April 2022',
        },
      },
    },
    {
      Key: '2022-04-personel-protection',
      CampaignType: 'civilianProtection',
      Status: 'closed',
      Photos: [
        '/media/custom-images/2022-04-personel-protection/2022-04-personel-protection-1.jpeg',
        '/media/custom-images/2022-04-personel-protection/2022-04-personel-protection-2.jpeg',
        '/media/custom-images/2022-04-personel-protection/2022-04-personel-protection-3.jpeg',
      ],
      StartDate: new Date('2022-04-01'),
      Languages: {
        de: {
          Title: 'Persönlicher Schutz – April 2022',
          ShortDonationDescription:
            'Unsere Freiwilligen haben eine Spendenaktion organisiert, um persönliche militärische Schutzausrüstung für die ukrainischen Verteidiger zu kaufen. Rund 6000€ kamen als Spenden zusammen. Die von dieser Initiative gekaufte Ausrüstung wurde erfolgreich direkt in die Ukraine geliefert.',
        },
        en: {
          Title: 'Personal Protection – April 2022',
          ShortDonationDescription:
            'Our volunteers have organized a fundraising campaign to buy personal military protection equipment for the Ukrainian defenders. About 6000€ were collected as donations. The gear bought from this initiative was successfully delivered directly to Ukraine.',
        },
      },
    },
  ];
}
